// src/App.js
import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LandingPage from './landing-page';
import LoginPage from './login';

function App() {
  const [token, setToken] = useState('');
  const [socket, setSocket] = useState(null);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage setToken={setToken} setSocket={setSocket} />} />
        <Route path="/landing" element={<LandingPage token={token} socket={socket} />} />
      </Routes>
    </Router>
  );
}

export default App;
