import { saveAs } from 'file-saver';
import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import chatHistoryIcon from '../assets/images/chat-list-icon.png'; // Replace with the actual path to your icon
import downloadIcon from '../assets/images/download-button-icon.png';
import newChatIcon from '../assets/images/new-chat-icon.png';
import SarovarLogoCircle from '../assets/images/sarovar-logo-circle.png';
import sarovarLogo from '../assets/images/sarovar-logo-final.png'; // Import the logo image
import sarovarLogoFull from '../assets/images/sarovar-logo-full.png';
import sendButton from '../assets/images/send-button.png';
import userAvatarFull from '../assets/images/user-avatar-full.png';
import userAvatar from '../assets/images/user-avatar.png';
import './landing-page.css'; // Import the external CSS file


const RECENT_RESPONSES_LIMIT = 20;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function LandingPage({ token }) {
    const [inputMessage, setInputMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isChatHistoryVisible, setIsChatHistoryVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [columnView, setColumnView] = useState(1); // Default is 1 column view
    const [loadingCharts, setLoadingCharts] = useState(false); // Define loadingCharts state
    const [activeTab, setActiveTab] = useState('table');
    const [tableData, setTableData] = useState([]);
    const [charts, setCharts] = useState([]);  // State to store chart images from API response
    const [metadata, setMetadata] = useState({});
    const [error, setError] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [dataViewWidth, setDataViewWidth] = useState('75%');
    const [responseHistory, setResponseHistory] = useState([]); // Track in-memory responses
    const [selectedMessageIndex, setSelectedMessageIndex] = useState(chatHistory.length - 1); // Default to the latest chat
    const location = useLocation();
    const [model, setModel] = useState(location.state?.model || 'normal'); // Defaults to 'normal'
    const chatBarRef = useRef(null);
    const chatHistoryIconRef = useRef(null);
    const chatContainerRef = useRef(null);
    const userAvatarRef = useRef(null); // Reference for user avatar
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {  // Check token instead of socket
            navigate('/');
            return;
        }
        // No need for WebSocket listeners, this useEffect can be removed
    }, [token, navigate]);


    const sendMessage = async () => {
        if (inputMessage) {
            setLoading(true);  // Start loading when message is sent
            setLoadingCharts(true); // Start loading charts as well

            // Add the user input to chat history
            setChatHistory((prevChatHistory) => [
                ...prevChatHistory,
                { user: inputMessage, bot: 'Processing...' }  // Show 'Processing...' until response is received
            ]);
            setInputMessage('');  // Clear the input field
            scrollToBottom();  // Scroll to the bottom of the chat

            try {
                // Make REST API call
                const endpoint = model === 'chain_based' ? '/messages_agent' : '/messages';
                console.log(endpoint)
                const response = await fetch(`${API_BASE_URL}${endpoint}?access_token=${encodeURIComponent(token)}`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ message: inputMessage })  // Send the user query
                });

                const responseData = await response.json();  // Get JSON response
                if (response.ok) {
                    // New response object for memory
                    const newResponse = {
                        message: responseData.message,
                        data: responseData.data,
                        metadata: responseData.metadata || {},
                        charts: responseData.charts || []
                    };

                    // Store response in memory, offload old responses to localStorage if limit is exceeded
                    setResponseHistory((prev) => {
                        const updatedHistory = [...prev, newResponse];

                        if (updatedHistory.length > RECENT_RESPONSES_LIMIT) {
                            // Remove the oldest response and push it to localStorage
                            const offloadedResponse = updatedHistory.shift();  // Remove the first item (oldest)
                            const storedResponses = JSON.parse(localStorage.getItem('responseHistory')) || [];
                            storedResponses.push(offloadedResponse);
                            localStorage.setItem('responseHistory', JSON.stringify(storedResponses));
                        }

                        return updatedHistory;  // Return updated history
                    });

                    // Update chat history to replace 'Processing...' with the actual bot response
                    setChatHistory((prevChatHistory) =>
                        prevChatHistory.map((entry) =>
                            entry.bot === 'Processing...' ? { ...entry, bot: responseData.message } : entry
                        )
                    );

                    // Update table data, metadata, and charts from the response
                    setTableData(responseData.data || []);  // Set table data
                    setMetadata(responseData.metadata || {});  // Set metadata for the table
                    setCharts(responseData.charts || []);  // Set charts

                    // Switch to Chart View if charts are available, otherwise stay in Table View
                    if (responseData.charts && responseData.charts.length > 0) {
                        setActiveTab('visualization');  // Switch to Chart View
                    } else {
                        setActiveTab('table');  // Stay in Table View
                    }

                    setLoadingCharts(false);  // Stop loading charts
                } else {
                    setError('Error retrieving data. Please try again.');
                }
            } catch (error) {
                setError('Error retrieving data. Please check your network and try again.');
            } finally {
                setLoading(false);  // Stop loading once the message is sent
            }
        }
    };



    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) { // Send message on Enter key without Shift
            e.preventDefault(); // Prevent adding a new line
            sendMessage();
        }
    };


    const handleMouseDown = (e) => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const minDataViewWidth = window.innerWidth * 0.5; // 50% of the screen
        const maxDataViewWidth = window.innerWidth * 0.875; // 87.5% of the screen
        const newWidth = Math.min(Math.max(e.clientX, minDataViewWidth), maxDataViewWidth);

        setDataViewWidth(`${newWidth}px`);
        updateAvatarMargin(); // Update avatar margin dynamically
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };


    // Handle changing the column view
    const handleColumnViewChange = (columns) => {
        setColumnView(columns);
    };

    const handleKhojiMessageClick = (index) => {
        setSelectedMessageIndex(index); // Set the selected message index
        let selectedResponse;

        if (index < responseHistory.length) {
            selectedResponse = responseHistory[index];
        } else {
            // Load from local storage
            const storedResponses = JSON.parse(localStorage.getItem('responseHistory')) || [];
            selectedResponse = storedResponses[index - responseHistory.length];
        }

        if (selectedResponse) {
            setTableData(selectedResponse.data);
            setMetadata(selectedResponse.metadata);
            setCharts(selectedResponse.charts);  // Set the charts for the selected message

            // Switch to Chart View if charts are available, otherwise switch to Table View
            if (selectedResponse.charts && selectedResponse.charts.length > 0) {
                setActiveTab('visualization');  // Switch to Chart View if charts exist
            } else {
                setActiveTab('table');  // Default to Table View if no charts are available
            }
        }
    };

    const updateAvatarMargin = () => {
        const chatSectionWidth = chatBarRef.current.clientWidth;
        const totalWidth = window.innerWidth;

        // Calculate the percentage of the chat section width relative to the total width
        const widthPercentage = chatSectionWidth / totalWidth;

        // Determine the new margin-left value, adjusting more precisely
        const newMarginLeft = 95 - (178 * widthPercentage);

        // Apply the new margin-left value
        userAvatarRef.current.style.marginLeft = `${newMarginLeft}vh`;
    };



    const columns = React.useMemo(() => {
        if (tableData.length > 0) {
            return Object.keys(tableData[0]).map((key) => ({
                Header: key,
                accessor: key,
                Cell: ({ value }) => {
                    if (metadata[key] === 'int64' || metadata[key] === 'float64') {
                        return new Intl.NumberFormat('en-US').format(value);
                    }
                    return value;
                },
                align: metadata[key] === 'int64' || metadata[key] === 'float64' ? 'right' : 'left',
            }));
        }
        return [];
    }, [tableData, metadata]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: tableData }, useSortBy);

    const exportToCSV = () => {
        const headers = columns.map(col => `"${col.Header}"`).join(',');
        const csvRows = rows.map(row =>
            columns.map(col => {
                let cellValue = row.original[col.accessor];
                if (metadata[col.accessor] === 'int64' || metadata[col.accessor] === 'float64') {
                    cellValue = new Intl.NumberFormat('en-US').format(cellValue);
                }
                if (typeof cellValue === 'string' && cellValue.includes(',')) {
                    cellValue = `"${cellValue}"`;
                }
                return cellValue;
            }).join(',')
        ).join('\n');

        const csvData = [headers, csvRows].join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'table_data.csv');
    };

    const handleNewChat = () => {
        setChatHistory([]);  // Clear the chat history
        setInputMessage(''); // Clear the input message
        setSelectedRowIndex(null); // Clear the selected row in the table if applicable
        setTableData([]); // Clear the table data
        setMetadata({}); // Clear the metadata
    };

    const toggleChatHistory = () => {
        setIsChatHistoryVisible(!isChatHistoryVisible);
    };

    // Loader component
    const Loader = () => (
        <div className="loader-container">
            <div className="spinner"></div>
            <p>Loading Data...</p>
        </div>
    );

    // Chart Loader component
    const ChartLoader = () => (
        <div className="loader-container">
            <div className="spinner"></div>
            <p>Loading Charts...</p>
        </div>
    );



    useEffect(() => {
        updateAvatarMargin(); // Initial calculation
    }, [dataViewWidth]); // Recalculate whenever the chat section width changes

    useEffect(() => {
        const updateMargin = () => {
            const chatBarWidth = chatBarRef.current.clientWidth;
            const totalWidth = window.innerWidth;

            // Calculate margin based on the chat bar width relative to the total screen width
            let margin = 44 * (chatBarWidth / totalWidth); // Example calculation in vw

            // Ensure the margin doesn't push the icon out of the visible area
            margin = Math.min(margin, 18); // Set a cap on margin for wide chat bars

            // Set the calculated margin as the margin-left for the chat history icon
            chatHistoryIconRef.current.style.marginLeft = `${margin}vw`;
        };

        // Initialize ResizeObserver to monitor chatBarRef width changes
        const resizeObserver = new ResizeObserver(updateMargin);
        resizeObserver.observe(chatBarRef.current);

        // Initial margin update
        updateMargin();

        // Cleanup observer on component unmount
        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        setSelectedMessageIndex(chatHistory.length - 1); // Select the latest chat by default
    }, [chatHistory]);




    return (
        <div className="main-container">
            {/* Data View Section */}
            <div className="data-view-section" style={{ width: dataViewWidth }}>
                {/* Sarovar Logo */}
                <div className="logo-container">
                    <img src={sarovarLogo} alt="Sarovar Logo" className="sarovar-logo" />
                    <div className='user-avatar' ref={userAvatarRef}>
                        <img src={userAvatar} alt="User Avatar" className="user-avatar" />
                    </div>
                </div>
                {/* Tabs section added here */}
                <div className="tabs-container">
                    <button
                        className={activeTab === 'table' ? 'active-tab' : ''}
                        onClick={() => setActiveTab('table')}
                    >
                        Data View
                    </button>
                    <button
                        className={activeTab === 'visualization' ? 'active-tab' : ''}
                        onClick={() => setActiveTab('visualization')}
                    >
                        Chart View
                    </button>
                </div>

                <div className="tab-content">
                    {activeTab === 'table' && (
                        <div className="table-tab-content">
                            {/* Export button */}
                            <div className="export-button-container">
                                {tableData.length > 0 && (
                                    <button onClick={exportToCSV} className="export-button">
                                        <span className="download-text">Download</span>
                                        <img src={downloadIcon} alt="Download CSV" className="download-icon" />
                                    </button>
                                )}
                            </div>

                            {/* PLACE YOUR EXISTING data-table-container CODE HERE */}
                            <div className="data-table-container">
                                {loading ? (
                                    <Loader /> // Show loader while loading
                                ) : tableData.length > 0 ? (
                                    <table {...getTableProps()} className="data-table">
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th
                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                            className="table-header"
                                                            style={{ textAlign: column.align }}
                                                        >
                                                            {column.render('Header')}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? ' 🔽'
                                                                        : ' 🔼'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {rows.map((row, index) => {
                                                prepareRow(row);
                                                const isSelected = index === selectedRowIndex;
                                                return (
                                                    <tr
                                                        {...row.getRowProps()}
                                                        onClick={() => setSelectedRowIndex(index)}
                                                        className={isSelected ? 'selected-row' : ''}
                                                    >
                                                        {row.cells.map(cell => (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                className="table-cell"
                                                                style={{ textAlign: cell.column.align }}
                                                                title={cell.value}
                                                            >
                                                                {cell.render('Cell')}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="no-data">
                                        No data available. Please enter a query.
                                    </div>
                                )}
                                {error && (
                                    <div className="error-message">
                                        {error}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {activeTab === 'visualization' && (
                        <div>
                            {/* Column View Buttons */}
                            {charts.length > 1 && (
                                <div className="column-view-buttons">
                                    <button
                                        className={columnView === 1 ? 'active' : ''}
                                        onClick={() => handleColumnViewChange(1)}
                                    >
                                        1 Column View
                                    </button>

                                    {charts.length >= 2 && (
                                        <button
                                            className={columnView === 2 ? 'active' : ''}
                                            onClick={() => handleColumnViewChange(2)}
                                        >
                                            2 Column View
                                        </button>
                                    )}

                                    {charts.length > 2 && (
                                        <button
                                            className={columnView === 3 ? 'active' : ''}
                                            onClick={() => handleColumnViewChange(3)}
                                        >
                                            3 Column View
                                        </button>
                                    )}
                                </div>
                            )}

                            <div
                                className="visualization-tab-content"
                                style={{
                                    display: 'grid',
                                    gap: '20px', // Adds space between charts
                                    gridTemplateColumns: `repeat(${columnView}, 1fr)`, // Dynamic column layout
                                    alignItems: 'start', // Align charts to the top
                                    justifyItems: 'center', // Center charts horizontally
                                    padding: '20px', // Add padding for better appearance
                                    overflowY: 'auto', // Enable scrolling for overflowing content
                                    maxHeight: '70vh', // Use full height of viewport
                                    boxSizing: 'border-box', // Include padding in height calculation
                                }}
                            >
                                {loadingCharts ? (
                                    <ChartLoader /> // Show loader when charts are loading
                                ) : charts.length > 0 ? (
                                    charts.map((chartData, index) => (
                                        <Plot
                                            key={index}
                                            data={chartData.data} // Use Plotly 'data' from API response
                                            layout={{
                                                ...chartData.layout,
                                                autosize: true, // Ensure the chart resizes properly
                                                margin: { l: 30, r: 30, t: 50, b: 30 }, // Adjust margins
                                            }}
                                            style={{
                                                width: columnView === 1 ? '100%' : '100%', // Full width in 1 column view
                                                height:
                                                    columnView === 1
                                                        ? 'calc(100vh - 230px)' // 1-column view height
                                                        : columnView === 2
                                                        ? '380px' // 2-column view height
                                                        : '280px', // 3-column view height
                                                maxWidth: '1000px', // Prevent excessive stretching
                                            }}
                                            config={{ responsive: true }} // Make Plotly charts responsive
                                        />
                                    ))
                                ) : (
                                    <div className="no-charts-message">No Charts Available</div>
                                )}

                                {error && <div className="error-message">{error}</div>}
                            </div>
                        </div>
                    )}


                </div>
            </div>

            {/* Chat Section */}
            <div
                ref={chatBarRef}
                className="chat-section"
                style={{ width: `calc(100% - ${dataViewWidth})` }}
            >
                {/* Resize Handle */}
                <div
                    onMouseDown={handleMouseDown}
                    className="resize-handle"
                />
                {/* New Chat Button */}
                <div className="new-chat-button-container">
                    <button className="new-chat-button" onClick={handleNewChat}>
                        <img src={newChatIcon} alt="New Chat" className="new-chat-icon" />
                        New Chat
                    </button>
                    {/* Chat History Toggle Button */}
                    <div className="toggle-chat-history-container" onClick={toggleChatHistory}>
                        <img src={chatHistoryIcon} alt="Chat History" className="chat-history-icon" ref={chatHistoryIconRef} />
                    </div>
                </div>

                {/* Chat History Container */}
                {isChatHistoryVisible && (
                    <div className="chat-history-container">
                        <h2 className="chat-history-title">All My Chat</h2>
                        {chatHistory.length > 0 ? (
                            chatHistory.map((chat, index) => (
                                <div key={index} className="chat-history-item">
                                    <p className="chat-history-text">{chat.user}</p>
                                    <p className="chat-history-text">{chat.bot}</p>
                                    <button className="share-button">Share</button>
                                </div>
                            ))
                        ) : (
                            <div className="no-chat-history">No chat history available.</div>
                        )}
                    </div>
                )}

                {/* Chat Content */}
                <div
                    ref={chatContainerRef}
                    className="chat-content"
                >

                    <div className="chat-intro">
                        <img src={SarovarLogoCircle} alt="Sarovar Logo" className="sarovar-logo-middle" />
                        <h2 className="intro-text">How can I help you today?</h2>
                    </div>
                    {chatHistory.length > 0 ? (
                        chatHistory.map((chat, index) => (
                            <div key={index} className={`chat-message ${index === selectedMessageIndex ? 'selected-chat' : ''}`}>
                                <div className="chat-label-container">
                                    <img src={userAvatarFull} alt="User Avatar" className="chat-label-avatar" />
                                    <div className="chat-label">You:</div>
                                </div>
                                <div className="user-message">
                                    <p>{chat.user}</p>
                                </div>
                                <div className="chat-label-container">
                                    <img src={sarovarLogoFull} alt="Sarovar Logo" className="chat-label-avatar" />
                                    <div className="chat-label">Khoji:</div>
                                </div>
                                <div onClick={() => handleKhojiMessageClick(index)} className="bot-message">
                                    <p>{chat.bot}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="start-conversation">
                            Start a conversation by typing a message below.
                        </div>
                    )}
                </div>

                {/* Input Area */}
                <div className="input-area">
                    <textarea
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        placeholder="Type your message..."
                        className="input-textarea"
                        onKeyDown={handleKeyDown} // Attach keydown handler here
                    />
                    <div className="send-button-container">
                        <img
                            src={sendButton}
                            alt="Send"
                            className="send-button-image"
                            onClick={sendMessage}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LandingPage;